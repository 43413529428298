<template>
  <main class="container">
    <ContentHeader :title="content_header.title" />

    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="page__body">
      <div>
        <div class="page__body__header">
          <ul class="nav page__tabs page__tabs1" id="page-Tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active text--capital active"
                id="pills-successAccounting-tab"
                data-toggle="tab"
                href="#pills-successAccounting"
                role="tab"
                aria-controls="pills-successAccounting"
                aria-selected="true"
                style="padding: 1.5rem 1.2rem"
                >Chart of account</a
              >
            </li>
            <li class="nav-item" v-show="!showDataset">
              <a
                class="nav-link text--capital"
                id="pills-accountMappingProduct-tab"
                data-toggle="tab"
                href="#pills-accountMappingProduct"
                role="tab"
                aria-controls="pills-accountMappingProduct"
                aria-selected="false"
                style="padding: 1.5rem 1.2rem"
                >Account Mapping</a
              >
            </li>
            <li class="nav-item" v-show="showDataset">
              <a
                class="nav-link text--capital"
                id="pills-accountMapping-tab"
                data-toggle="tab"
                href="#pills-accountMapping"
                role="tab"
                aria-controls="pills-accountMapping"
                aria-selected="false"
                style="padding: 1.5rem 1.2rem"
                >Account Mapping</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text--capital"
                id="pills-transactionsLog-tab"
                data-toggle="tab"
                href="#pills-transactionsLog"
                role="tab"
                aria-controls="pills-transactionsLog"
                aria-selected="false"
                style="padding: 1.5rem 1.2rem"
                >Transactions Log</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text--capital"
                id="pills-failedTransaction-tab"
                data-toggle="tab"
                href="#pills-failedTransaction"
                role="tab"
                aria-controls="pills-failedTransaction"
                aria-selected="false"
                style="padding: 1.5rem 1.2rem"
                >Failed Transactions</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text--capital"
                id="pills-UploadGUID-tab"
                data-toggle="tab"
                href="#pills-UploadGUID"
                role="tab"
                aria-controls="pills-UploadGUID"
                aria-selected="false"
                style="padding: 1.5rem 1.2rem"
                >Upload Transactions</a
              >
            </li>
          </ul>
        </div>

        <div class="tab-content" id="page-TabsContent">
          <!-- View successful transaction -->
          <div
            class="tab-pane fade show active"
            id="pills-successAccounting"
            role="tabpanel"
            aria-labelledby="pills-successAccounting-tab"
          >
            <div class="box">
              <a
                id="excel__download"
                class="btn download-box"
                v-if="userCanExportToExcel"
                @click="processDownload"
              >
                Download Template
              </a>
            </div>

            <hr style="border: 1.3px solid #e5e5e5" />

            <div class="box">
              <div class="dash-box">
                <label for="file-upload" class="btn-black"
                  >Choose XLSX file</label
                >
                <p class="tbody-child">{{ " " }}{{ file.name }}</p>
                <input id="file-upload" type="file" @change="onFileChange" />
              </div>

              <div class="hid-box">
                <button
                  @click="uploadFile"
                  :disabled="!excel_file.length"
                  :class="!excel_file.length ? 'disable-btn' : 'btn-black'"
                >
                  {{ submitting ? "Please wait..." : "Upload Template" }}
                </button>
              </div>
            </div>

            <hr style="border: 1.3px solid #e5e5e5" />

            <div class="ma-table">
              <div class="ma-thead">
                <div
                  v-for="(text, i) in thead_texts"
                  :key="i"
                  class="thead-child"
                >
                  {{ text }}
                </div>
              </div>

              <hr style="border: 0.6px solid #e5e5e5" />
              <div v-if="allAccounts.length > 0">
                <div v-for="(item, i) in allAccounts" :key="i">
                  <div class="ma-tbody">
                    <div style="word-wrap: break-word" class="tbody-child">
                      {{ item.account_name }}
                    </div>
                    <div class="tbody-child">
                      {{ item.account_number }}
                    </div>
                    <!-- <div>
                      <div
                        class="tbody-child text-success"
                        v-if="item.is_debit_account === false"
                      >
                        Credit
                      </div>
                      <div
                        v-else-if="item.is_debit_account === true"
                        class="tbody-child text-danger"
                      >
                        Debit
                      </div>
                      <div v-else></div>
                    </div> -->
                    <!-- <div v-if="allMappedAccounts.length" class="tbody-child">
                      {{ mappedParameter(item) }}
                    </div> -->
                    <div class="tbody-child">
                      {{ item.date_created | moment }}
                    </div>

                    <div class="tbody-child dropdown">
                      <button
                        class="btn dropdown-toggle action"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#editAccModal"
                          @click="openEditModal(item)"
                        >
                          Edit
                        </a>
                        <!-- <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#editMappingModal"
                          v-if="!isAccountMapped(item)"
                          @click="openMappingModal(item)"
                        >
                          Map Account
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#editMappingModal"
                          v-if="isAccountMapped(item)"
                          @click="openEditMappingModal(item)"
                        >
                          Edit Mapped Account
                        </a>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          v-if="isAccountMapped(item)"
                          @click="openDeletedMappedAccount(item)"
                        >
                          Unmap Account
                        </a> -->
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          v-if="!isAccountMapped(item)"
                          @click="openDeleteAccountingModal(item)"
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>

                  <hr style="border: 0.6px solid #e5e5e5" />
                </div>
              </div>

              <div v-else-if="loadingAccount && !allAccounts.length">
                <div
                  class="d-flex align-items-center justify-content-center p5"
                >
                  <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </div>

              <div v-else>
                <div
                  class="d-flex flex-column align-items-center justify-content-center p5"
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >
                    No data available</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- View Transaction Log -->
          <financeLog
            :loading="loadingTransactionLogs"
            :sageTransactionsData="sageTransactionsData"
            :allProducts="allProducts"
            @getTransData="getTransactionsData"
          />

          <!-- Mapping Account -->
          <mapAccount
            v-show="showDataset"
            :loading="loadingDataSets"
            :mapDatasets="allDataSets"
            :allAccounts="allAccounts"
            :allMappedAccounts="allMappedAccounts"
            :loadingPostDataSet="loadingPostDataSet"
            :allProducts="allProducts"
            :prodId="prodId"
            @accountMapped="accountMapped"
            @showConfiguredProduct="showConfiguredProduct"
          />
          <ProductMapping
            :loading="loadingConfiguredProduct"
            :allProducts="allProducts"
            :mapDatasets="allDataSets"
            :mappedProducts="mappedProducts"
            @configuredProducts="configuredProducts"
            @showMappedDataset="showMappedDataset"
          />

          <!-- Failed Transactions -->
          <failedTransactions
            :loading="loadingFailedTransactions"
            :allFailedTransactionsData="allFailedTransactionsData"
            :allProducts="allProducts"
            @getFailedTransData="getFailedTransactions"
          />

          <!-- Upload GUID -->
          <uploadGuid :loading="false" @accountMapped="accountMapped" />
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editAccModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                id="editAccountingCloseButton"
                data-dismiss="modal"
                @click="clearEditAccount"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit Account</h5>
          </div>
          <div class="modal__body">
            <form @submit.prevent="updateAccount">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Account Name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Account Name"
                      v-model="getSingleAccountData.account_name"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Account Number</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Account Number"
                      v-model="getSingleAccountData.account_number"
                    />
                  </div>
                </div>
                <!-- <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Account Type</label>
                    <select
                      class="select--lg w-100"
                      id="form__label"
                      v-model="getSingleAccountData.is_debit_account"
                    >
                      <option disabled value="null">
                        Select Account Type
                      </option>
                      <option
                        v-for="item in accountTypes"
                        :key="item.id"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div> -->
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!submitting"
                  >
                    update account
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                    v-if="submitting"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    v-if="!submitting"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearEditAccount"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="modal modal__right"
      id="editMappingModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                id="mappingCloseButton"
                data-dismiss="modal"
                @click="clearMapping"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 v-if="isEditMapping" class="modal__head__title text--capital">Edit Map Account DataSet</h5>
            <h5 v-else class="modal__head__title text--capital">Map Account DataSet</h5>
          </div>
          <div class="modal__body">
            <form @submit.prevent="mapAccount">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Account Name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Account Name"
                      disabled
                      v-model="getSingleAccountData.account_name"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Account Number</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Account Number"
                      disabled
                      v-model="getSingleAccountData.account_number"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Data set</label>
                    <select
                      class="select--lg w-100"
                      id="form__label"
                      v-model="dataset"
                    >
                      <option disabled value="null">
                        Select Data Set to Map
                      </option>
                      <option
                        v-for="item in allDataSets"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!loadingPostDataSet"
                  >
                    {{ isEditMapping ? "Edit mapping" : "Upload mapping" }}
                  </button>
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      modal__confirm__button modal__confirm__button--delete
                    "
                    v-if="loadingPostDataSet"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    v-if="!loadingPostDataSet"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearMapping"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->

    <DeleteModal
      :show="showDeleteAccounting"
      @close="closeDeleteAccountingModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Delete Account
            </h5>
            <p>Please confirm that you want to delete this account</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteAccountingModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="submitting"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="sendDeleteKey"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- <DeleteModal :show="showDeletedMappedAccModal" @close="closeDeletedMappedAccount">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Unmap Account
            </h5>
            <p>Please confirm that you want to unmap this account</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="
                  button button--md
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="closeDeletedMappedAccount"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="loadingUnMap"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--md
                  save-changes
                  modal__confirm__button--delete
                  fitrem
                "
                @click="unMapAccount"
                v-else
              >
                unmap
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal> -->
  </main>
</template>
<script>
import { mapState } from "vuex";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  GET_ALL_ACCOUNTS,
  POST_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ALL_DATASET_MAPPING,
  GET_SAGE_TRANSACTIONS,
  POST_DATASET_MAPPING,
  GET_MAPPED_ACCOUNTS,
  DELETE_DATASET_MAPPING,
  UPDATE_DATASET_MAPPING,
  GET_FAILED_TRANSACTIONS,
} from "@/core/services/store/coreBanking.module";

import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import ApiService from "@/core/services/api.service";
import LoanApiService from "@/core/services/api.loan.service";

export default {
  name: "ViewFinances",
  data() {
    return {
      content_header: {
        title: "Accounting",
      },
      thead_texts: [
        "Account Name",
        "Account Number",
        // "Account Type",
        // "Mapped Parameter",
        "Date Created",
        "Action",
      ],
      file: "",
      excel_file: [],
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      getSingleAccountData: "",
      showDeleteAccounting: false,
      dataset: null,
      accountTypes: [
        { id: 1, name: "Credit", value: false },
        { id: 2, name: "Debit", value: true },
      ],
      showDeletedMappedAccModal: false,
      loadingUnMap: false,
      submitting: false,
      isEditMapping: false,
      getSingleMappedData: "",
      deposits: [],
      loans: [],
      mappedProducts: [],
      loadingConfiguredProduct: false,
      prodId: null,
      showDataset: false,
    };
  },
  components: {
    DeleteModal,
    financeLog: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/SageIntegration/FinanceTransactionsLog.vue"
      ),
    mapAccount: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/SageIntegration/AccountMapping.vue"
      ),
    ProductMapping: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/SageIntegration/ProductMappedToDataset.vue"
      ),
    failedTransactions: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/SageIntegration/FailedTransactions.vue"
      ),
    uploadGuid: () =>
      import(
        /* WebpackChunkName: "viewFailedTransactionTable" */ "@/view/pages/SageIntegration/UploadGUID.vue"
      ),
  },

  computed: {
    ...mapState({
      allAccounts: (state) => {
        return state.coreBankingModule.allAccounts;
      },
      allDataSets: (state) => {
        return state.coreBankingModule.allDataSets;
      },
      allMappedAccounts: (state) => {
        return state.coreBankingModule.allMappedAccounts;
      },
      sageTransactionsData: (state) => {
        return state.coreBankingModule.allSageTransactions;
      },
      loadingTransactionLogs: (state) => {
        return state.coreBankingModule.loadingTransactionLogs;
      },
      errorTransactionLogs: (state) => {
        return state.coreBankingModule.error;
      },
      allFailedTransactionsData: (state) => {
        return state.coreBankingModule.allFailedTransactions;
      },
      loadingFailedTransactions: (state) => {
        return state.coreBankingModule.loadingFailedTransactions;
      },
      failedTransactionsError: (state) => {
        return state.coreBankingModule.failedTransactionsError;
      },
      errorGetDataSetError: (state) => {
        return state.coreBankingModule.errorAllDataSets;
      },
      loadingAccount: (state) => {
        return state.coreBankingModule.loadingAccounts;
      },
      loadingDataSets: (state) => {
        return state.coreBankingModule.loadingDataSets;
      },
      // submitting: (state) => {
      //   return state.coreBankingModule.loadingPostAccounts;
      // },
      errorGetAccount: (state) => {
        return state.coreBankingModule.errorAccounting;
      },
      errorMapping: (state) => {
        return state.coreBankingModule.mappingError;
      },
      errorSubmitting: (state) => {
        return state.coreBankingModule.errorPostAccounting;
      },
      allDataSetsSuccess: (state) => {
        return state.coreBankingModule.allDataSetsSuccess;
      },
      loadingPostDataSet: (state) => {
        return state.coreBankingModule.loadingPostDataSet;
      },
      user: (state) => state.auth.user,
    }),
    userCanApproveBulk() {
      const disbursement = this.user.scopes.find(
        (item) => item.scopeName === Role.CreditOperations
      );
      const { permissionsList } = disbursement;
      return permissionsList.CanBulkRepaymentUpload;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },
    allProducts() {
      return [...this.deposits, ...this.loans].sort((a, b) =>
        a.productName.localeCompare(b.productName)
      );
    },
  },

  watch: {
    errorGetAccount(newVal) {
      if (newVal) {
        this.alertError(newVal);
      }
    },
    errorSubmitting(newVal) {
      if (newVal) {
        this.alertError(newVal);
      }
    },
    errorTransactionLogs(newVal) {
      if (newVal) {
        this.alertError(newVal);
      }
    },
    errorMapping() {
      if (this.errorMapping) return this.alertError(this.errorMapping);
    },
    failedTransactionsError() {
      if (this.failedTransactionsError)
        return this.alertError(this.failedTransactionsError);
    },
  },

  methods: {
    getAccountingData() {
      this.$store.dispatch(GET_ALL_ACCOUNTS);
    },

    getFailedTransactions(data = null) {
      const parameters = {
        pageIndex: data ? data.pageIndex : 1,
        pageSize: data ? data.pageSize : 20,
        creationDateFrom: data
          ? data.creationDateFrom
          : moment(new Date()).startOf("date").add(1, "hours"),
        creationDateTo: data
          ? data.creationDateTo
          : moment(new Date()).endOf("date").add(1, "hours"),
        transactionIds: data ? data.transactionIds : [],
        sortColumn: data ? data.sortColumn : "",
        sortDesending: data ? data.sortDesending : true,
      };
      this.$store.dispatch(GET_FAILED_TRANSACTIONS, parameters);
    },

    loadProduct() {
      ApiService.get(
        `DepositAdministration/GetActiveDeactiveDepositProduct?PageIndex=${1}&PageSze=${1000000000}&Status=${false}`
      ).then((resp) => {
        this.deposits = resp.data.data;
      });
    },

    configuredProducts(key = "", type = "") {
      this.loadingConfiguredProduct = true;
      ApiService.get(
        `DepositAdministration/GetAllProductMappedToDataSet?productKey=${key}&productType=${type}`
      ).then((resp) => {
        this.loadingConfiguredProduct = false;
        this.mappedProducts = resp.data.data;
      });
    },

    showMappedDataset(key) {
      this.prodId = key;
      this.showDataset = true;

      let removeDom = document.getElementById(
        "pills-accountMappingProduct-tab"
      );
      removeDom.classList.remove("active");

      document.querySelector(".nav-link").classList.remove("active");
      let toggle = document.getElementById("pills-accountMapping-tab");
      toggle.classList.add("active");

      let removeSubDom = document.getElementById("pills-accountMappingProduct");
      removeSubDom.classList.remove("show", "active");

      document.querySelector(".tab-pane").classList.remove("show", "active");
      let component = document.getElementById("pills-accountMapping");
      component.classList.add("show", "active");
    },

    showConfiguredProduct() {
      this.configuredProducts();
      this.prodId = null;
      this.showDataset = false;

      let removeDom = document.getElementById(
        "pills-accountMappingProduct-tab"
      );
      removeDom.classList.remove("active");

      document.querySelector(".nav-link").classList.remove("active");
      let toggle = document.getElementById("pills-accountMappingProduct-tab");
      toggle.classList.add("active");

      let removeSubDom = document.getElementById("pills-accountMapping");
      removeSubDom.classList.remove("show", "active");

      document.querySelector(".tab-pane").classList.remove("show", "active");
      let component = document.getElementById("pills-accountMappingProduct");
      component.classList.add("show", "active");
    },

    loadLoanProduct() {
      LoanApiService.get(
        `LoanProduct/getloanproducts?pageindex=${1}&pagesize=${1000000000}&activestatus=${false}`
      ).then((resp) => {
        this.loans = resp.data.data;
      });
    },

    getDataSetMapping() {
      this.$store.dispatch(GET_ALL_DATASET_MAPPING);
    },

    getMappedAccounts() {
      this.$store.dispatch(GET_MAPPED_ACCOUNTS);
    },

    getTransactionsData(data = null) {
      const parameters = {
        pageindex: data ? data.pageindex : 1,
        pagesize: data ? data.pagesize : 20,
        SortDescending: data ? data.SortDescending : true,
        status: data ? data.status : null,
        CreationDateFrom: data ? data.CreationDateFrom : null,
        CreationDateTo: data ? data.CreationDateToCreationDateTo : null,
        SortColumn: data ? data.SortColumn : null,
      };
      this.$store.dispatch(GET_SAGE_TRANSACTIONS, parameters);
    },

    openEditModal(data) {
      this.getSingleAccountData = data;
    },

    clearEditAccount() {
      this.getSingleAccountData = "";
    },

    clearMapping() {
      this.getSingleAccountData = "";
    },

    openDeleteAccountingModal(data) {
      this.showDeleteAccounting = true;
      this.getSingleAccountData = data;
    },

    openDeletedMappedAccount(data) {
      const findAccount = this.allMappedAccounts.find(
        (acc) => acc.account_request_key === data.request_key
      );
      this.showDeletedMappedAccModal = true;
      this.getSingleAccountData = findAccount;
    },

    closeDeleteAccountingModal() {
      this.showDeleteAccounting = false;
      this.getSingleAccountData = "";
    },

    closeDeletedMappedAccount() {
      this.showDeletedMappedAccModal = false;
      this.getSingleAccountData = "";
    },

    openMappingModal(data) {
      this.getSingleAccountData = data;
    },

    openEditMappingModal(data) {
      this.getSingleAccountData = data;
      this.isEditMapping = true;
      const findAccount = this.allMappedAccounts.find(
        (acc) => acc.account_request_key === data.request_key
      );
      this.getSingleMappedData = findAccount;
      this.dataset = findAccount.dataset;
    },

    isAccountMapped(item) {
      const findAccount = this.allMappedAccounts.find(
        (acc) => acc.account_request_key === item.request_key
      );
      return findAccount ? true : false;
    },

    mappedParameter(item) {
      const dataSet = this.allMappedAccounts.find(
        (acc) => acc.account_request_key === item.request_key
      )?.dataset;
      const getParam = this.allDataSets.find((param) => param.id === dataSet);
      return getParam ? getParam.name : "";
    },

    accountMapped() {
      this.getAccountingData();
      this.getTransactionsData();
      this.getMappedAccounts();
      this.getFailedTransactions();
    },

    onFileChange(event) {
      this.file = event.target.files ? event.target.files[0] : "";

      if (this.file) {
        const reader = new FileReader();
        let self = this;

        reader.onload = function (e) {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          /* DO SOMETHING WITH workbook HERE */
          let worksheet = workbook.Sheets[sheetName];
          let arr_data = XLSX.utils.sheet_to_json(worksheet);
          arr_data.type = Array;

          for (let i = 0; i < arr_data.length; i++) {
            const debit =
              arr_data[i]["Account Type(Credit/Debit)"].toLowerCase() ===
              "debit"
                ? true
                : arr_data[i]["Account Type(Credit/Debit)"].toLowerCase() ===
                  "credit"
                ? false
                : null;
            let account_name = arr_data[i]["Account Name"];
            let account_number = `${arr_data[i]["Account Number"]}`;
            let is_debit_account = debit;

            self.excel_file.push({
              account_name,
              account_number,
              is_debit_account,
            });
          }
        };
        reader.readAsArrayBuffer(this.file);
      }
    },

    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Template Download",
        Subject: "Accounting Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Accounting");

      let sheetData = [
        ["Account Name", "Account Number", "Account Type(Credit/Debit)"],
      ];

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Accounting"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },

    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },

    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "accounting.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    uploadFile() {
      if (this.excel_file.length) {
        this.submitting = true;
        let formData = { sageAccountCreateRequest: this.excel_file };
        this.$store.dispatch(POST_ACCOUNT, formData).then(() => {
          this.alertSuccess("Upload Successful.");
          this.getAccountingData();
          this.submitting = false;
          this.getTransactionsData();
          this.excel_file = [];
          this.file = "";
        });
      } else {
        this.alertError("No file was added for upload");
      }
    },

    updateAccount() {
      this.submitting = true;
      let formData = {
        request_key: this.getSingleAccountData.request_key,
        account_number: this.getSingleAccountData.account_number,
        account_name: this.getSingleAccountData.account_name,
        is_debit_account: this.getSingleAccountData.is_debit_account,
      };
      this.$store.dispatch(UPDATE_ACCOUNT, formData).then(() => {
        this.alertSuccess("Account Updated Successfully.");
        this.getAccountingData();
        this.getTransactionsData();
        document.getElementById("editAccountingCloseButton").click();
        this.submitting = false;
        this.getSingleAccountData = "";
      });
    },

    sendDeleteKey() {
      this.submitting = true;
      this.$store
        .dispatch(DELETE_ACCOUNT, this.getSingleAccountData.request_key)
        .then(() => {
          this.alertSuccess("Account deleted Successfully.");
          this.getAccountingData();
          this.getTransactionsData();
          this.closeDeleteAccountingModal();
          this.submitting = false;
          this.getSingleAccountData = "";
        });
    },

    unMapAccount() {
      this.loadingUnMap = true;
      this.$store
        .dispatch(DELETE_DATASET_MAPPING, this.getSingleAccountData.request_key)
        .then(() => {
          this.alertSuccess("Account was successfully unmap.");
          this.getAccountingData();
          this.getTransactionsData();
          this.getMappedAccounts();
          this.closeDeletedMappedAccount();
          this.loadingUnMap = false;
          this.getSingleAccountData = "";
        });
    },

    isParameterMapped() {
      const dataSets = this.allMappedAccounts.map((acc) => acc.dataset);
      if (dataSets.includes(this.dataset)) {
        return true;
      }

      return false;
    },

    mapAccount() {
      if (this.isParameterMapped()) {
        this.alertError(
          "This selected data set parameter has been mapped to an account and cannot be map to another account"
        );
        return;
      }

      if (this.isEditMapping) {
        let form = {
          dataset: this.dataset,
          account_request_key: this.getSingleMappedData.account_request_key,
          request_key: this.getSingleMappedData.request_key,
        };
        this.$store.dispatch(UPDATE_DATASET_MAPPING, form).then(() => {
          this.alertSuccess("Account mapping update is successful.");
          this.getAccountingData();
          this.getTransactionsData();
          this.getMappedAccounts();
          document.getElementById("editMappingModal").click();
          this.getSingleAccountData = "";
          this.getSingleMappedData = "";
          this.dataset = null;
          this.isEditMapping = false;
        });
      } else {
        let form = {
          datasetAccountMappingCreateRequest: [
            {
              dataset: this.dataset,
              account_request_key: this.getSingleAccountData.request_key,
              is_debit_account: this.getSingleAccountData.is_debit_account
                ? this.getSingleAccountData.is_debit_account
                : false,
            },
          ],
        };
        this.$store.dispatch(POST_DATASET_MAPPING, form).then(() => {
          this.alertSuccess("Account mapping is successful.");
          this.getAccountingData();
          this.getTransactionsData();
          this.getMappedAccounts();
          document.getElementById("editMappingModal").click();
          this.getSingleAccountData = "";
          this.dataset = null;
        });
      }
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    colorType(status) {
      if (status == true) {
        return "color:#000000";
      } else {
        return "color:#DE791B";
      }
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  mounted() {
    this.getAccountingData();
    this.getFailedTransactions();
    this.getTransactionsData();
    this.getDataSetMapping();
    this.getMappedAccounts();
    this.loadProduct();
    this.loadLoanProduct();
    this.configuredProducts();
  },
};
</script>
<style lang="scss" scoped>
.page__tabs1 {
  display: flex !important;
}
.no-border-box {
  width: 82%;
  // border: 1px solid #C4C4C4;
  // box-sizing: border-box;
  // border-radius: 7px;
  height: 45px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 45px;
  background: #000000;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #ffffff;
}

.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 75%;
  /* border: 1px dashed #c4c4c4; */
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 25%;
  text-align: center;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 25%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
